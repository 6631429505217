<template>
  <div>
    <table class="w-full table-auto border-separate">
      <thead>
        <tr>
          <th class="w-1/2 text-left text-gray-400 ">Servicio</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="service in services"
          :key="service.id"
          @click="addService(service)"
          class="cursor-pointer hover:bg-gray-50">
          <td class="text-left">
            <div class="flex flex-row items-start space-x-2">
              <span>
                <i :class="[ isServiceSelected(service)
                  ? 'bx bx-check-square text-xl text-purple-500'
                  : 'bx bx-checkbox text-xl text-gray-300' ]"
                />
              </span>
              <div>
                <span class="block font-bold">{{ service.name }}</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ServiceEnumDict } from '@/constants';

export default {
  name: 'ServicesSearchList',
  props: {
    services: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      selectedServices: [],
      ServiceEnumDict,
    };
  },
  methods: {
    isServiceSelected(service) {
      return this.selectedServices.some((s) => s.id === service.id);
    },
    addService(service) {
      const indexOfCurrentService = this.selectedServices.findIndex((s) => s.id === service.id);
      if(indexOfCurrentService === -1) {
        service.serviceId = service.id;
        this.selectedServices.push(service);
      }
      else
        this.selectedServices.splice(indexOfCurrentService, 1);
      this.$emit('onChangeSelectedServices', this.selectedServices);
    },
  },
};
</script>
